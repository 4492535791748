import React from 'react';
import { Item } from './components';
import { Box } from 'theme-ui';
import SwipeableCarousel from './SwipeableCarousel.jsx';

function Carousel({ images }) {
  return (
    <Box
      sx={{
        minWidth: '300px',
        maxWidth: '600px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <SwipeableCarousel>
        {images.map((img) => (
          <Item img={img} key={img} />
        ))}
      </SwipeableCarousel>
    </Box>
  );
}

export default Carousel;
