import styled from 'styled-components';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

export const Item = styled.div`
  text-align: center;
  width: 100%;
  aspect-ratio: 1/1;
  background-image: ${(props) => `url(${props.img})`};
  background-size: cover;
`;

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${(props) => (props.sliding ? 'none' : 'transform 1s ease')};
  transform: ${(props) => {
    if (!props.sliding) return 'translateX(calc(-100% - 0px))';
    if (props.dir === PREV) return 'translateX(calc(2 * (-100% - 0px)))';
    return 'translateX(0%)';
  }};
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 5px 5px 20px 7px rgba(168, 168, 168, 1);
`;

export const OuterWrapper = styled.div`
  width: 90%;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  margin-right: 0px;
  order: ${(props) => props.order};
`;

export const OutContainer = styled.div`
  display: block;
`;

export const Arrow = styled.div`
  z-index: 100;
  font-size: 18px;
  display: inline-block;
  background: transparent;
  position: absolute;
  width: 40px;
  opacity: 0.3;
  top: 50%;
  margin-top: -13px;
  align-items: center;
  cutsor: pointer;
  background: white;
  &:hover {
    opacity: 1;
  }
  &:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
`;

export const PrevArrow = styled(Arrow)`
  left: 0;
  &:before {
    content: '<';
  }
`;

export const NextArrow = styled(Arrow)`
  right: 0;
  margin-right: -5px;
  padding-left: 10px;
  &:before {
    content: '>';
  }
`;
