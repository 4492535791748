/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import {
  jsx,
  Box,
  Text,
  Flex,
  Label,
  Button,
  Select,
  Checkbox,
} from 'theme-ui';
import { useShoppingCart } from 'use-shopping-cart';
import CartItem from './CartItem.jsx';
import CartHeader from './CartHeader.jsx';

const DESTINATION_DEFAULT = 'Choose Option';
const Cart = ({ onCloseHandler }) => {
  const { cartDetails, cartCount, formattedTotalPrice } = useShoppingCart();

  const [disableCheckout, setDisableCheckout] = useState(false);
  const [destination, setDestination] = useState(null);
  const [optIn, setOptIn] = useState(false);

  async function handleCheckout(event) {
    event.preventDefault();

    if (cartCount > 0) {
      setDisableCheckout(true);
      try {
        const response = await fetch('/.netlify/functions/createSession', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            items: cartDetails,
            destination: destination,
            optIn,
          }),
        });

        const resp = await response.json();
        if (resp.sessionUrl) {
          window.location = resp.sessionUrl;
        } else {
          console.error('***** no sessionUrl', resp);
        }
      } catch (err) {
        console.error(err);
        setDisableCheckout(false);
      }
    } else {
      setDisableCheckout(false);
    }
  }

  function handleOptIn() {
    setOptIn(!optIn);
  }

  function handleSelectDestination(event) {
    event.preventDefault();
    const selectedDestination = event.target.value;

    if (selectedDestination === DESTINATION_DEFAULT) {
      setDestination(null);
    } else {
      setDestination(selectedDestination);
    }
  }

  const enableCheckout =
    !disableCheckout && cartCount > 0 && destination != null;

  const items = [];
  for (const sku in cartDetails) {
    const entry = cartDetails[sku];
    items.push(<CartItem entry={entry} sku={sku} key={sku} />);
  }

  if (Object.keys(cartDetails).length === 0) {
    items.push(
      <Box sx={{ textAlign: 'center' }} sku={null} key={null}>
        <h3>No items in cart</h3>
      </Box>
    );
  }
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <CartHeader onCloseHandler={onCloseHandler} />
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '40px',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            justifyItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box>{items}</Box>
          {cartCount > 1 && (
            <Text sx={{ textAlign: 'center' }}>
              Total Items in Cart: {cartCount}
            </Text>
          )}
        </Flex>
        <Flex
          sx={{
            padding: '20px',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Label htmlFor='shipping-destination'>Delivery Options:</Label>
            <div>
              <Select
                name='shipping-destination'
                onChange={handleSelectDestination}
                sx={{
                  width: '240px',
                }}
              >
                <option>{DESTINATION_DEFAULT}</option>
                <option>Local Pickup (San Marcos, CA)</option>
                <option>USA</option>
              </Select>
            </div>
          </Flex>
          <Text sx={{ textAlign: 'center', padding: '30px 10px 0px' }}>
            Subtotal: {formattedTotalPrice}
          </Text>
          <Text
            sx={{
              fontSize: '12px',
              textAlign: 'center',
              padding: '5px 10px 30px',
            }}
          >
            Total with shipping calculated at checkout.
          </Text>
          <Flex sx={{ paddingBottom: '20px' }}>
            <Label
              sx={{
                fontSize: '12px',
                alignItems: 'center',
              }}
            >
              Please check if you would like receive emails on new announcements
              and sales
              <Checkbox
                type='checkbox'
                checked={optIn}
                onChange={handleOptIn}
                sx={{ paddingLeft: '5px' }}
              ></Checkbox>
            </Label>
          </Flex>
          <Button
            sx={{
              backgroundColor: enableCheckout ? 'green' : 'lightGray',
              width: '100%',
            }}
            onClick={handleCheckout}
            disabled={!enableCheckout}
          >
            Checkout
          </Button>
          <Text
            sx={{
              textAlign: 'center ',
              fontSize: '16px',
              color: 'black',
              fontWeight: '700',
              paddingBottom: '20px',
              paddingTop: '20px',
            }}
            onClick={onCloseHandler}
          >
            Continue Shopping
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Cart;
