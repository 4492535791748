import React, { useState, useEffect } from 'react';
import { ThemeProvider, Box } from 'theme-ui';
import Products from './components/Products';
import Cart from './components/Cart';
import Header from './components/header';
import Footer from './components/footer';
import {} from 'theme-ui';
import { theme } from './theme';

const App = () => {
  const [products, setProducts] = useState([]);
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    fetch(`/.netlify/functions/getProducts`)
      .then((response) => response.json())
      .then((data) => {
        const productsResp = [...data.products];

        setProducts(productsResp);
      })
      .catch((err) => console.error(err));
  }, []);

  const onCloseHandler = () => {
    setShowCart(false);
  };

  const onCartHandler = () => {
    setShowCart(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: 'white',
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 1000,
        }}
      >
        <Header onCartHandler={onCartHandler}></Header>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
          paddingTop: '10vh',
        }}
      >
        <Products products={products || []} />
        <Footer
          sx={{
            bottom: 0,
          }}
        />
      </Box>

      <Box
        sx={{
          top: 0,
          width: '100%',
          backgroundColor: 'white',
          height: '100%',
          position: 'fixed',
          zIndex: 200,
          right: showCart ? 0 : '-100%',
          opacity: showCart ? 1 : 0,
          '@media screen and (min-width: 52em)': {
            right: showCart ? 0 : '-40%',
            width: '40%',
            maxWidth: '480px',
          },
          boxShadow: '5px 5px 20px 7px rgba(168, 168, 168, 1)',
          transition: 'right 0.5s, opacity 0.25s',
        }}
      >
        <Cart onCloseHandler={onCloseHandler} />
      </Box>
    </ThemeProvider>
  );
};
export default App;
