/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Grid, Text, Heading, Button, Flex, Select } from 'theme-ui';
import { useState } from 'react';
import { useShoppingCart } from 'use-shopping-cart';
import { toCurrency } from '../../util';
import Carousel from '../Carousel';

const getSizeText = (size) => {
  let text = size;
  if (size === 'SM') {
    text = 'Small';
  } else if (size === 'MD') {
    text = 'Medium';
  } else if (size === 'LG') {
    text = 'Large';
  } else if (size === 'XL') {
    text = 'Extra Large';
  }
  return text;
};

const SizeSelector = ({ sizes, onSizeChange }) => {
  return (
    <Box>
      <Select
        defaultValue={'Medium'}
        onChange={(e) => {
          onSizeChange(e.target.value);
        }}
      >
        {sizes.map(({ size, qty, sku }) => (
          <option disabled={qty > 0 ? null : 'disabled'} key={sku} value={sku}>
            {getSizeText(size)}
          </option>
        ))}
      </Select>
    </Box>
  );
};

const Product = ({ name, sizes }) => {
  const { addItem } = useShoppingCart();
  const { images, currency = 'USD', description } = sizes[0];

  const availableSizes = sizes.map(({ metadata, sku }) => ({
    size: metadata.SIZE,
    qty: metadata.QTY,
    sku,
  }));
  const [selectedSku, setSku] = useState(sizes[0].sku);
  const [selectedSkuPrice] = useState(sizes[0].unit_amount);
  const [selectedName, setSeslectedName] = useState(sizes[0].name);
  const [cartImage, setCartImage] = useState(images[0]);

  const onSizeChange = (sku) => {
    setSku(sku);
    setSeslectedName(sizes.find((size) => sku === size.sku).name);
    setCartImage(sizes.find((size) => sku === size.sku).images[0]);
  };

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        '@media screen and (min-width: 52em)': {
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '50px',
        },
        paddingTop: '25px',
      }}
    >
      <Box
        sx={{
          width: '90%',
          '@media screen and (min-width: 52em)': {
            width: '45%',
            maxWidth: '600px',
            display: 'none',
          },
        }}
      >
        <Heading
          pb='2rem'
          sx={{
            fontSize: '20px',
            color: 'primary',
            fontFamily: 'heading',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {name}
        </Heading>
      </Box>
      <Box
        sx={{
          width: '90%',
          '@media screen and (min-width: 52em)': {
            width: '45%',
            maxWidth: '600px',
          },
        }}
      >
        <Carousel images={images} />
      </Box>

      <Box
        sx={{
          minWidth: '350px',
          width: 'min-content',
        }}
      >
        <Box>
          <Grid
            sx={{
              flexBasis: 2,
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              gridTemplateRows:
                '[row1-start] 0% [row1-end] 5% [third-line] min-content [last-line] 10%',
              '@media screen and (min-width: 52em)': {
                gridTemplateRows:
                  '[row1-start] 0% [row1-end] 12% [third-line] min-content [last-line] 10%',
              },
            }}
          >
            <div></div>
            <Heading
              pb='2rem'
              sx={{
                fontSize: '20px',
                color: 'primary',
                fontFamily: 'heading',
                fontWeight: 'bold',
                textAlign: 'center',
                opacity: 0,
                '@media screen and (min-width: 52em)': {
                  opacity: '100%',
                },
              }}
            >
              {name}
            </Heading>
            <Text
              pb='2rem'
              sx={{
                fontFamily: 'heading',
                fontSize: '16px',
                textAlign: 'justify',
                paddingBottom: 0,
              }}
            >
              {description}
            </Text>
            <SizeSelector sizes={availableSizes} onSizeChange={onSizeChange} />

            <Grid sx={{ alignItems: 'center' }} columns={['1fr 3fr']}>
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: '20px',
                }}
              >
                {toCurrency({ price: selectedSkuPrice, currency })}
              </Box>
              <Button
                onClick={() => {
                  addItem({
                    name: selectedName,
                    sku_id: selectedSku,
                    mainImage: cartImage,
                    currency,
                    price: selectedSkuPrice,
                  });
                }}
                backgroundColor={'black'}
              >
                Add To Cart
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div></div>
    </Flex>
  );
};

export default Product;
