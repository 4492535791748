import { Box, Grid } from 'theme-ui';
import Product from '../Product';

const extractName = (product) => {
  return product.sizes[0].name.split(' (')[0];
};

const Products = ({ products }) => {
  return (
    <Box sx={{ paddingBottom: '40px', paddingTop: '30px' }}>
      <Grid columns={[1, 1, 1, 2]} sx={{ gridRowGap: '64px' }}>
        {products.map((product) => (
          <Product
            key={extractName(product)}
            name={extractName(product)}
            sizes={product.sizes}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default Products;
