import { ThemeProvider, Box, Text } from 'theme-ui';
import Header from './components/header';
import Footer from './components/footer';
import {} from 'theme-ui';
import { theme } from './theme';

const ThankYou = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: 'white',
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 10,
        }}
      >
        <Header hideCart></Header>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
          paddingTop: '40vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text sx={{ textAlign: 'center', padding: '0 20px' }}>
            Thank you for your purchase! You will receive an email receipt soon.
          </Text>
        </Box>
        <Footer
          sx={{
            bottom: 0,
          }}
        />
      </Box>
    </ThemeProvider>
  );
};
export default ThankYou;
