import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CartProvider } from 'use-shopping-cart';
import './index.css';
import App from './App.jsx';
import ThankYou from './ThankYou.jsx';
import Contact from './Contact.jsx';

ReactDOM.render(
  <CartProvider
    mode='payment'
    cartMode='client-only'
    stripe={(process.env, process.env.STRIPE_PK_KEY)}
    successUrl='https://walensky-shop.netlify.app/thankyou.html'
    cancelUrl='https://walensky-shop.netlify.app'
    currency='USD'
    allowedCountries={['US', 'GB', 'CA']}
  >
    <Router>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/thankyou' element={<ThankYou />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </Router>
  </CartProvider>,
  document.getElementById('root')
);
