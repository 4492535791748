import React from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  Wrapper,
  CarouselContainer,
  CarouselSlot,
  PREV,
  NEXT,
  PrevArrow,
  NextArrow,
  OutContainer,
} from './components';

const getOrder = (index, pos, numItems) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

const getInitialState = (numItems) => ({
  pos: numItems - 1,
  sliding: false,
  dir: NEXT,
});

const Carousel = (props) => {
  const numItems = React.Children.count(props.children);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState(numItems)
  );

  const slide = (dir) => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: 'stopSliding' });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <OutContainer {...handlers}>
      <Wrapper>
        <PrevArrow onClick={() => slide(PREV)}></PrevArrow>
        <CarouselContainer dir={state.dir} sliding={state.sliding}>
          {React.Children.map(props.children, (child, index) => (
            <CarouselSlot order={getOrder(index, state.pos, numItems)}>
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
        <NextArrow onClick={() => slide(NEXT)}></NextArrow>
      </Wrapper>
    </OutContainer>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1,
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1,
      };
    case 'stopSliding':
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default Carousel;
