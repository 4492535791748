import { Grid, Text, Flex, Image, Button } from 'theme-ui';
import { useShoppingCart } from 'use-shopping-cart';

const CartItem = ({ entry, sku }) => {
  const { incrementItem, decrementItem } = useShoppingCart();

  return (
    <Grid
      style={{
        gridTemplateColumns: '6fr 0fr 4fr 2fr',
        ggridColumnGap: '0px',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 20px 20px 0',
      }}
    >
      <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image sx={{ padding: '0 10px' }} src={entry.mainImage} />
        <Text
          sx={{
            fontSize: '12px',
            minWidth: '60px',
          }}
        >
          {entry.name}
        </Text>
      </Flex>
      <div></div>
      <Text
        sx={{
          alignItems: 'center',
          baselineShift: 'center',
          paddingLeft: '10px',
          fontSize: '14px',
        }}
      >
        {entry.formattedValue}
      </Text>

      <Grid
        sx={{
          gridTemplateColumns: '30px 10px 30px',
          justifyContent: 'right',
          alignItems: 'center',
        }}
      >
        <Button onClick={() => decrementItem(sku)}>-</Button>
        <Text>{entry.quantity}</Text>
        <Button onClick={() => incrementItem(sku)}>+</Button>
      </Grid>
    </Grid>
  );
};

export default CartItem;
