import React from 'react';
import { ThemeProvider, Box, Text } from 'theme-ui';
import Header from './components/header';
import Footer from './components/footer';
import {} from 'theme-ui';
import { theme } from './theme';

const Contact = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: 'white',
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 10,
        }}
      >
        <Header hideCart></Header>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
          paddingTop: '40vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text sx={{ textAlign: 'center', padding: '0 20px' }}>
            If you have any issues with your order, we are happy to help. Please
            send us an <a href='mailto:info@walenskyjiujitsu.com'>email</a>.
          </Text>
        </Box>
        <Footer
          sx={{
            bottom: 0,
          }}
        />
      </Box>
    </ThemeProvider>
  );
};
export default Contact;
