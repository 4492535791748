import { Box, Text, Flex } from 'theme-ui';

const CartHeader = ({ onCloseHandler }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px',
        margin: '0px',
        backgroundColor: '#ccc',
        alignItems: 'center'
      }}
    >
      <Text sx={{ fontFamily: 'sans-serif', marginTop: '5px' }}>
        Cart Summary
      </Text>
      <Box onClick={() => onCloseHandler()}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            transform: 'rotate(45deg)',
          }}
        >
          <svg
            viewBox='0 0 64 64'
            fill='black'
            xmlns='http://www.w3.org/2000/svg'
            className='snipcart__icon--medium snipcart__icon--angled snipcart__icon'
          >
            <path
              d='M33.23 30.77H48v2.46H33.23V48h-2.46V33.23H16v-2.46h14.77V16h2.46v14.77z'
              fill='black'
            ></path>
          </svg>
        </Box>
      </Box>
    </Flex>
  );
};

export default CartHeader;
