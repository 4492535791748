import { Flex, Box, Text } from 'theme-ui';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#ccc', padding: '20px', margin: 0 }}>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Text sx={{ fontSize: '12px', fontWeight: 700 }}>
          COPYRIGHT 2024 WALENSKY JIU JITSU
        </Text>
        <Text sx={{ fontSize: '12px', fontWeight: 700 }}>
          <a href='/contact'>CONTACT</a>
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
