export const theme = {
  fonts: {
    body: 'sans-serif',
    heading: 'sans-serif',
    monospace: 'Menlo, monospace',
  },
  colors: {
    text: '#3c3c3c',
    background: '#fefefe',
    primary: '#3c3c3c',
  },
  text: {
    default: {
      color: 'text',
      fontSize: 3,
      font: 'sans-serif',
    },
    block: {
      variant: 'paragraph',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
    },
  },
  breakpoints: ['42em', '52em', '96em'],
};
